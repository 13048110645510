import React, { useEffect, useState } from 'react';

import Button from './forms/Button';
import containsElement from '../../utils/utilsFunctions';
import Icon from './Icon';

interface ComponentProps {
  shown?: boolean;
  className?: string;
  bulletIcon?: string | boolean;
  peek?: string | boolean;
  onActiveFn?: () => void;
  onClick?: () => void;
  heading?: string;
  children?: React.ReactNode;
  noAutoHide?: boolean | string;
  filterProp?: string | boolean;
  attached?: boolean;
  tags?: boolean;
  multi?: unknown;
  filter?: boolean;
  context?: { filters };
}

const CollapsibleBlock = ({
  shown,
  className,
  bulletIcon,
  peek,
  onActiveFn,
  onClick,
  heading,
  children,
  noAutoHide,
  filterProp,
  attached,
  tags,
  context,
}: ComponentProps): JSX.Element => {
  const [active, setActive] = useState<boolean>(false);
  const [collapsed, setCollapsed] = useState<boolean>(shown || false);
  const [childrenActive, setChildrenActive] = useState([]);
  let filter;

  useEffect(() => {
    setCollapsed(shown)
  }, [shown])

  const fadeOutMenu = (evt): void => {
    const area = filter;
    if (area && evt.target) {
      const contain = containsElement(area, evt.target, null);
      if (!contain) {
        setCollapsed(false);
      }
    }
  };

  useEffect(() => {
    if (!noAutoHide) {
      window.addEventListener('click', fadeOutMenu);
    }

    if (filterProp) {
      const currentChildrenActive = context?.filters[heading] || [];
      setChildrenActive(currentChildrenActive);
    }

    return (): void => {
      if (!noAutoHide) {
        window.removeEventListener('click', fadeOutMenu);
      }

      if (heading === 'Tabla de contenidos' || heading === 'Descripción') {
        setCollapsed(true);
      }
    };
  }, []);

  const onActiveHandler = (): void => {
    if (onActiveFn) {
      onActiveFn();
    }
  };

  const collapseBlock = (): void => {
    setCollapsed((coll) => !coll);
    setActive((act) => !act);
    if (onClick) {
      onClick();
    }
  };

  useEffect(() => {
    if (active) {
      onActiveHandler();
    }
  }, [active]);

  return (
    <div
      ref={(q): void => {
        filter = q;
      }}
      className={`collapsibleBlock${collapsed ? ' collapsed' : ''} ${className || ''}${
        active || childrenActive.length > 0 ? '' : ' inactive'
      }${bulletIcon ? ' bulletTitle' : ''}`}
    >
      <Button type="button" onClick={collapseBlock} className="collapsibleHeading">
        {bulletIcon ? <Icon className={collapsed ? 'bullet-minus' : 'bullet-plus'} /> : null}

        {heading}
      </Button>

      <div
        className={`collapsibleContent ${collapsed ? '' : 'hidden'} ${tags ? 'tags' : ''} ${
          peek ? 'peek' : ''
        } ${attached ? 'attached' : ''}`}
      >
        {children}
      </div>
    </div>
  );
};

export default CollapsibleBlock;
