/* eslint-disable import/prefer-default-export */

import {
  GetTagsCustomDocument,
  GetTagsCustomQuery,
  GetTagsCustomQueryVariables,
  GetTagsDocument,
  GetTagsQuery,
  Tag,
  TagSearch,
} from '../../graphqlTypes';
import { initializeApollo } from '../../graphql/apollo';
import { Response } from '../../models/response.model';

export const getAllTags = async (): Promise<Response<Tag[]>> => {
  try {
    const { query } = initializeApollo();

    const {
      data: { getTags },
    } = await query<GetTagsQuery>({
      query: GetTagsDocument,
      fetchPolicy: 'cache-first',
    });

    return {
      ok: !!getTags,
      data: getTags,
    };
  } catch (error: any) {
    return {
      ok: false,
      messageError: error?.message,
    };
  }
};

export const getTagsByFilter = async (
  variables: GetTagsCustomQueryVariables,
): Promise<Response<TagSearch[]>> => {
  try {
    const { query } = initializeApollo();

    const {
      data: { getTagsCustom },
    } = await query<GetTagsCustomQuery>({
      query: GetTagsCustomDocument,
      variables,
      fetchPolicy: 'cache-first',
    });

    return {
      ok: !!getTagsCustom,
      data: getTagsCustom,
    };
  } catch (error: any) {
    return {
      ok: false,
      messageError: error?.message,
    };
  }
};
