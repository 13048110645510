import dynamic from 'next/dynamic';
import React from 'react';

const LoaderOval = dynamic(() => import('../LoadingOval'), { ssr: false })

interface LoaderProps {
  className?: string;
  isSpinner?: boolean;
}

const Loader = ({ className, isSpinner}: LoaderProps): JSX.Element => {
  return (
    <div className={`loader ${className || ''}`}>
      {
        isSpinner ? (
          <div style={{ width: '100px', background: 'none', opacity: 1 }}>
            <LoaderOval
              ariaLabel='loading'
              height={100}
              width={100}
              strokeWidth={2.2}
              color='red'
              secondaryColor='white'
            />
          </div>
        ) : (
          <>
            <div className="one" />
            <div className="two" />
            <div className="three" />
          </>
        )}
    </div>
  );
};

export default Loader;
