/* eslint-disable import/prefer-default-export */
import {
  AggregateDocument,
  AggregateQuery,
  AggregateQueryVariables,
  Aggregate,
} from '../../../../graphqlTypes';
import { initializeApollo } from '../../../../graphql/apollo';
import { Response } from '../../../../models/response.model';

export const getAggregate = async (
  variables: AggregateQueryVariables,
): Promise<Response<Aggregate>> => {
  try {
    const { query } = initializeApollo();

    const {
      data: { aggregate },
    } = await query<AggregateQuery>({
      query: AggregateDocument,
      variables,
      fetchPolicy: 'cache-first',
    });

    return {
      ok: !!aggregate,
      data: aggregate,
    };
  } catch (error: any) {
    return {
      ok: false,
      messageError: error?.message,
    };
  }
};
