import React, { useEffect, useState } from 'react';

interface ButtonGroupProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
  initialIndex?: string | number;
  block?: boolean;
  className?: string;
}

const ButtonGroup = ({
  children,
  initialIndex,
  block,
  className: classes,
}: ButtonGroupProps): JSX.Element => {
  const [state, setState] = useState({ active: initialIndex });

  useEffect(() => {
    setState((prevState) => ({ ...prevState, active: initialIndex }));
  }, [initialIndex]);

  return (
    <div className={`buttonGroup ${classes || ''} ${block ? 'disabled' : ''}`}>
      {React.Children.map(children, (child: React.ReactElement, i) => {
        if (child) {
          return React.cloneElement(child, {
            className: (child.props.className || '') + (i === state.active ? ' active' : ''),
          });
        }
        return null;
      })}
    </div>
  );
};

export default ButtonGroup;
