import ActionFilter from './actionFilter';
import { FiltersState } from '../../components/common/Filters/model/filters.model';

const reducerFilter = (state: FiltersState, action: ActionFilter): FiltersState => {
  switch (action.type) {
    case 'set_state': {
      return {
        ...state,
        ...action.filter,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducerFilter;
