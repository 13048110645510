/* eslint-disable no-nested-ternary */
import React, { useEffect, useReducer, useRef, useState } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import useScrollBarPage from '../../../hooks/useScrollbarPage';
import { Filter, NResults } from './model/filters.model';
import { getProducts } from '../../../services/productListing.service';
import { getAllTags } from '../../tags/tags.service';
import { getAggregate } from './service/filter.service';
import CollapsibleBlock from '../CollapsibleBlock';
import Icon from '../Icon';
import ButtonGroup from '../forms/ButtonGroup';
import Loader from '../layout/Loader';

import constants, { SCREEN_SIZES } from '../../../utils/constants';
import { ResultsAggregate } from '../../../graphqlTypes';
import useWindowSize from '../../../hooks/useWindowSize';
import useFiltersAndOrder from '../../../hooks/useFiltersAndOrder';
import reducerFilter from '../../../reducer/filter/reducerFilter';
import { getDataFromStorage, NameStorage } from '../../../utils/services/localStorage.service';

const Scrollbar = dynamic(() => import('../layout/Scrollbar'));
const RenderFilter = dynamic(() => import('./RenderFilter'));
const OtherFilters = dynamic(() => import('./OtherFilter'));
const FilterTypeFilter = dynamic(() => import('./FilterTypeFilter'));
const Button = dynamic(() => import('../forms/Button'));

const ordersDic = {
  calendar: 'Novedad',
  visited: 'Más visitados',
  alfabeta: 'Orden Alfabético',
  alfabetaBack: 'Orden Alfabético',
  euro: 'Precio',
  euroHigh: 'Precio',
  bestSellers: 'Más Vendidos',
};

const { sectionWithAreasOfInterest } = constants;

interface FiltersProps {
  category?: string;
  type?: string;
  search?: string;
  hidden?: boolean;
}

const Filters = ({ type, search, hidden, category: categoryProps }: FiltersProps): JSX.Element => {
  const { asPath } = useRouter();

  const [state, setState] = useReducer(reducerFilter, {
    orderOptions: false,
    productType: false,
    areasOfInterest: false,
    price: false,
    others: false,
    openFilters: false,
    fixed: false,
    expandedAreas: false,
    nResults: {},
    type,
    search,
    loadingFilter: false,
    category: categoryProps,
  });

  const { getOrder, changeOrder, resetFilter, getFilters, changeFilters, filter, setOpenFilter } =
    useFiltersAndOrder();

  const { isTheActualPageSave, pageScrollPosition } = useScrollBarPage();

  const { documentWidth } = useWindowSize();
  const [contador, setContador] = useState(0);
  const tagCategoryActive = useRef('');
  const tagCategoryActiveResult = useRef<ResultsAggregate[]>(null);

  const getFilterProductType = (filterData?: Filter): string[] => {
    if (filter['Categorías']?.length)
      return filterData ? [...filter['Categorías']] : [...filter['Categorías']];

    if (categoryProps) return [categoryProps];

    return constants.allProductTypes;
  };

  const getAreasOfInterest = (filterData?: Filter): number[] => {
    const areaOfInterestFromStorage = getDataFromStorage<number>(NameStorage.areaOfInterest);
    return sectionWithAreasOfInterest.includes(getFilterProductType(filterData)[0]) &&
      areaOfInterestFromStorage
      ? [areaOfInterestFromStorage]
      : null;
  };

  const getTags = async (): Promise<void> => {
    const { ok, data } = await getAllTags();
    if (!ok) return;

    setState({ type: 'set_state', filter: { tags: data } });
  };

  useEffect(() => {
    getTags();
  }, [asPath]);

  const changeOrderFn = (filterData: string): void => {
    setState({
      type: 'set_state',
      filter: {
        orderOptions: !state.orderOptions,
      },
    });

    changeOrder(filterData, false, true);
  };

  const getCurrentProducts = async (nResults: NResults): Promise<void> => {
    const variables = {
      limit: 1,
      skip: 0,
      sort: 'prices.sale',
      order: -1,
      product_type: getFilterProductType(),
      lang: getFilters()?.Idioma?.length && getFilters().Idioma,
      areas_of_interest: getAreasOfInterest(),
      tags: getFilters()?.Tags?.length && getFilters().Tags,
      type: state.type,
      searchGeneral: state.search,
      prox: false,
      area_prox: undefined,
    };

    const { ok, data } = await getProducts(variables);

    if (!ok) return;

    const [totalResults] = data || [];
    const max = totalResults?.priceWithDiscount;
    const changeState: Record<string, unknown> = {};
    changeState.nResults = { ...nResults, price: { min: 0, max } };

    setState({
      type: 'set_state',
      filter: {
        ...changeState,
      },
    });
  };

  const getResultsFiltering = async (filterActiveData: string, filters?: Filter): Promise<void> => {
    const currentContador = contador + 1;
    setContador(currentContador);

    const { category } = state;

    if (category === 'magazines' || filterActiveData !== 'others') return;

    const filterProductType = getFilterProductType(filters);
    const areas_of_interest = getAreasOfInterest();

    const filterData = filters ? { ...filters } : { ...filter };

    const variables = {
      collectionName: 'Books',
      group: 'tags',
      product_type: filterProductType,
      lang: filterData?.Idioma?.length && filterData.Idioma,
      rangePrice: filterData?.Precio?.length && filterData.Precio[0],
      areas_of_interest,
      tags: filterData?.Tags?.length && filterData.Tags,
      type: state.type,
      searchGeneral: state.search,
      prox: filterData && filterData.Tags?.some((tag) => tag.items?.includes('SIN PUBLICAR')),
      area_prox: undefined,
    };

    setState({
      type: 'set_state',
      filter: {
        loadingFilter: true,
      },
    });

    const { ok, data } = await getAggregate(variables);

    if (!ok) {
      return;
    }

    const { nResults } = state;
    const totalResults = data?.results;

    const changeState: Record<string, unknown> = {};
    changeState.nResults = { ...nResults, others: totalResults };
    changeState.loadingFilter = false;

    setState({
      type: 'set_state',
      filter: {
        ...changeState,
      },
    });

    getCurrentProducts(changeState.nResults);
  };

  useEffect(() => {
    if (!isTheActualPageSave() && pageScrollPosition !== undefined) {
      resetFilter();
      changeOrder(
        sectionWithAreasOfInterest.includes(categoryProps) ? 'calendar' : 'visited',
        false,
        true,
      );
      getResultsFiltering('others', {});
      return;
    }

    getResultsFiltering('others');
  }, [pageScrollPosition?.pageName]);

  useEffect(() => {
    setState({ type: 'set_state', filter: { category: categoryProps } });
  }, [categoryProps]);

  useEffect(() => {
    setState({
      type: 'set_state',
      filter: {
        type,
        search,
        orderOptions: false,
        productType: false,
        areasOfInterest: false,
        price: false,
        others: false,
      },
    });
  }, [type, search]);

  const getClassButton = (filterData: string, name: string): string => {
    if (filterData === 'Categorías' && state?.[name]) {
      return 'focused active';
    }

    if (filter[filterData] || filterData === 'Categorías') {
      return 'active';
    }

    if (state[name]) {
      return 'focused';
    }

    return '';
  };

  const resetFilters = (): void => {
    setState({
      type: 'set_state',
      filter: {
        productType: false,
        areasOfInterest: false,
        price: false,
        others: false,
        openFilters: false,
      },
    });

    resetFilter();
  };

  const productTypeOpen = (): void => {
    const newState =
      documentWidth > SCREEN_SIZES.MAX_TABLET ? !state.openFilters : !state.productType;

    if (documentWidth <= SCREEN_SIZES.MAX_TABLET) {
      setOpenFilter(newState);
    }

    setState({
      type: 'set_state',
      filter: {
        productType: !state.productType,
        areasOfInterest: false,
        price: false,
        others: false,
        openFilters: newState,
      },
    });

    if (!newState) return;

    getResultsFiltering('productType');
  };

  useEffect(() => {
    getResultsFiltering('others');
  }, [filter]);

  const otherOpen = (): void => {
    const newState = !state.others;
    setState({
      type: 'set_state',
      filter: {
        productType: false,
        areasOfInterest: false,
        price: false,
        others: !state.others,
        openFilters: newState,
      },
    });

    setOpenFilter(newState);
    tagCategoryActive.current = '';
    tagCategoryActiveResult.current = null;
  };

  const openFilters = (): void => {
    setState({
      type: 'set_state',
      filter: {
        openFilters: true,
      },
    });

    setOpenFilter(true);
  };

  const closeFilters = (): void => {
    setState({
      type: 'set_state',
      filter: {
        openFilters: false,
      },
    });

    setOpenFilter(false);
  };

  const resetFiltersMobile = (): void => {
    closeFilters();
    resetFilters();
  };

  const changeFiltersTags = (item, group: ResultsAggregate[]): void => {
    const tags = filter.Tags ? filter.Tags : [];
    const tagIndex = tags.findIndex((t) => t.prop === item.prop);

    if (tagIndex !== -1) {
      const index = tags[tagIndex].items.indexOf(item.value);
      if (index !== -1 && tags[tagIndex].items.length > 1) {
        tags[tagIndex].items.splice(index, 1);
      } else if (index !== -1 && tags[tagIndex].items.length === 1) {
        tags.splice(tagIndex, 1);
      } else {
        tags[tagIndex].items.push(item.value);
      }
    } else {
      const newItem = { prop: item.prop, items: [item.value] };
      tags.push(newItem);
    }

    tagCategoryActive.current = item.prop;
    tagCategoryActiveResult.current = group;
    changeFilters({ prop: 'Tags', value: tags }, undefined, true);
  };

  const isFilterActive = (): boolean => {
    if (filter.Tags?.length || filter.Precio) {
      return true;
    }
    return false;
  };

  if (documentWidth > SCREEN_SIZES.MAX_TABLET) {
    return (
      <div className={`filtersWrapper ${state.fixed ? 'fixed' : ''}`}>
        <div className="filters">
          <ButtonGroup className="filtersHeader horizontal">
            <Button
              className={getClassButton('Categorías', 'productType')}
              onClick={productTypeOpen}
            >
              <div className="orderForStyle">
                <p>Ordenar Por</p>

                <div className="filterOptionSelected">
                  <Icon
                    className={
                      ordersDic[getOrder()]
                        ? getOrder()
                        : ['book'].includes(categoryProps)
                        ? 'calendar'
                        : 'visited'
                    }
                    nameAriaLabel="category"
                  />

                  <p>
                    {ordersDic[getOrder()]
                      ? ordersDic[getOrder()]
                      : ['book'].includes(categoryProps)
                      ? 'Novedad'
                      : 'Más visitados'}
                  </p>
                </div>
              </div>
            </Button>

            <Button className={getClassButton('Tags', 'others')} onClick={otherOpen}>
              <p>Filtrar por</p>
            </Button>
          </ButtonGroup>

          {isFilterActive() ? (
            <Button
              noStyle
              onClick={resetFilters}
              className={`filtersReset ${
                !Object.keys(getFilters()).length ? 'inactive' : ''
              } activeFilters`}
            >
              <span>Eliminar filtros</span>
            </Button>
          ) : null}

          {state.openFilters ? (
            <RenderFilter
              changeOrderFn={changeOrderFn}
              otherOpen={otherOpen}
              productTypeOpen={productTypeOpen}
              changeFiltersTags={changeFiltersTags}
              tagCategoryActive={tagCategoryActive.current}
              tagCategoryActiveResult={tagCategoryActiveResult.current}
              categoryProps={state.category}
              loadingFilter={state.loadingFilter}
              nResults={state.nResults}
              others={state.others}
              productType={state.productType}
              tags={state.tags}
            />
          ) : null}
        </div>
      </div>
    );
  }

  return (
    <div
      className={`filters${state.openFilters ? ' active' : ' inactive'} ${
        state.fixed ? 'fixed' : ''
      } ${!state.openFilters && hidden ? 'hidden' : ''}`}
    >
      {state.openFilters ? (
        <div className="filterWrapper">
          <div className="filterHeader">
            <Button noStyle iconLeft iconClass="filtro">
              Filtros
            </Button>

            <Button noStyle>
              <Icon onClick={resetFiltersMobile} className="close" nameAriaLabel="closed" />
            </Button>
          </div>

          <div className="filterContent">
            <Scrollbar>
              <CollapsibleBlock
                multi
                onClick={productTypeOpen}
                heading="Ordenar por"
                className="filter"
              >
                <FilterTypeFilter categoryProps={state.category} changeOrderFn={changeOrderFn} />
              </CollapsibleBlock>

              <CollapsibleBlock filter multi onClick={otherOpen} heading="Otros" className="filter">
                {state.loadingFilter ? (
                  <Loader />
                ) : (
                  <OtherFilters
                    otherOpen={otherOpen}
                    changeFiltersTags={changeFiltersTags}
                    tagCategoryActive={tagCategoryActive.current}
                    tagCategoryActiveResult={tagCategoryActiveResult.current}
                    categoryCurrent={state.category}
                    nResults={state.nResults}
                    tags={state.tags}
                  />
                )}
              </CollapsibleBlock>
            </Scrollbar>
          </div>
          <div className="filterFooter actionsHolder">
            <Button
              onClick={closeFilters}
              className="rounded bordered cta"
              iconClass="check"
              iconRight
            >
              Aplicar
            </Button>
          </div>
        </div>
      ) : (
        <div className="filterHeader">
          <Button noStyle iconLeft iconClass="filtro" onClick={openFilters}>
            Filtros
          </Button>
          <Button noStyle>
            <Icon onClick={resetFiltersMobile} className="refresh" nameAriaLabel="refresh" />
          </Button>
        </div>
      )}
    </div>
  );
};

export default Filters;
