import {
  Book,
  CurrentProductsDocument,
  CurrentProductsQuery,
  CurrentProductsQueryVariables,
} from '../graphqlTypes';
import { initializeApollo } from '../graphql/apollo';
import { Response } from '../models/response.model';
import { booksCount } from '../graphql/queries';

export const getProducts = async (
  variables: CurrentProductsQueryVariables,
): Promise<Response<Book[]>> => {
  try {
    const { query } = initializeApollo();

    const {
      data: { books },
    } = await query<CurrentProductsQuery>({
      query: CurrentProductsDocument,
      variables,
      fetchPolicy: 'cache-first',
    });

    return {
      ok: !!books,
      data: books as Book[],
    };
  } catch (error: any) {
    return {
      ok: false,
      messageError: error?.message,
    };
  }
};

export const getProductsCount = async (
  variables: CurrentProductsQueryVariables,
): Promise<Response<number>> => {
  try {
    const { query } = initializeApollo();

    const {
      data: { totalProducts },
    } = await query({
      query: booksCount,
      variables,
      fetchPolicy: 'cache-first',
    });

    return {
      ok: totalProducts >= 0,
      data: totalProducts,
    };
  } catch (error: any) {
    return {
      ok: false,
      messageError: error?.message,
    };
  }
};
