function containsElement(area, elem, inner): boolean {
  let find = false;
  if (!inner) {
    const contains = area.contains(elem);
    if (contains) {
      find = true;
    }
  } else {
    const contains = area.innerText.indexOf(elem.innerText);
    if (contains !== -1) {
      find = true;
    }
  }

  if (!find) {
    const { children } = area;
    if (children.length > 0) {
      for (let i = 0; i < children.length; i += 1) {
        const findItem = containsElement(children[i], elem, true);
        if (findItem) {
          find = true;
        }
      }
    }
  }

  return find;
}

export default containsElement;
